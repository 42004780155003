// Libs
import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router';
import moment from 'moment';
 
//  Services
import API from 'API';

// Components
import Layout from 'components/layout/Layout';
import Loader from 'components/common/Loader';
import Login from 'components/Login';

// Components - pages
import DaySheet from 'components/DaySheet';
import Appt from 'components/Appt';

window.moment = moment;

//-------------------------------------------------------------------------------------------------------------------

class App extends Component {

    static VERSION = 'm1.22';

    constructor(props) {
        super(props);

        this.setTitle = this.setTitle.bind(this);
        this.setTopNavContent = this.setTopNavContent.bind(this);
        this.checkLogin = this.checkLogin.bind(this);

        window.updateSyncStatus = () => null;

        this.state = {
            isLoading: true,
            loginInfo: null
        };

    }

    async componentDidMount() {
        this.checkLogin();
    }

    async checkLogin() {
        // Determine if logged in
        let loginInfo = null;
        try {
            loginInfo = await API.call('account/get-login-info');
        } catch (e) {
            // Ignore error - loginInfo will be null
        }
        await API.init();

        // Update UI
        this.setState({
            loginInfo,
            isLoading: false
        });
    }

    setTitle(page) {
        const { loginInfo } = this.state;
        const accountName = (loginInfo ? loginInfo.account.accountName : null);
        const parts = [
            page,
            accountName
        ].filter(p => !!p);
        if (parts.length == 0) {
            parts.push('ProSweep3');
        }
        document.title = parts.join(' | ');
    }

    setTopNavContent(content) {
        this.setState({ topNavContent: content });
    }

    //-------------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            loginInfo
        } = this.state;
        const rootNode = document.querySelector('#root');

        // Loading login status
        if (isLoading) {
            return (<Loader />);
        }

        // Not logged in yet
        if (!loginInfo || !loginInfo.user) {
            rootNode.classList.add('not-logged-in');
            return this.renderNotLoggedIn();
        }

        // Logged in
        rootNode.classList.remove('not-logged-in');
        return this.renderLoggedIn();
    }

    renderNotLoggedIn() {
        return (
            <Login
                onLogIn={this.checkLogin}
            />
        );
    }

    renderLoggedIn() {
        const {
            loginInfo,
            topNavContent
        } = this.state;
        
        const getChildProps = (props) => ({
            ...props.match.params,
            history: props.history,
            loginInfo,
            fns: {
                setTitle: this.setTitle,
                setTopNavContent: this.setTopNavContent
            }
        });

        return (
            <Layout loginInfo={loginInfo} topNavContent={topNavContent}>
                <Switch>
                    <Route path="/day-sheet/:tab?" render={(props) =>
                        <DaySheet {...getChildProps(props)} />}
                    />
                    <Route path="/appt/:id/:tab?" render={(props) =>
                        <Appt {...getChildProps(props)} />}
                    />
                    <Redirect to="/day-sheet" />
                </Switch>

            </Layout>
        );
    }
}

export default withRouter(App);
